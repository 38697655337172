import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Image from "next/image";
import Link from "next/link";


const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link href="/">
                        <Box sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}>
                            <Image src="images/couple.png" height={32} width={32} alt={"Icon"}/>
                        </Box>
                    </Link>
                    <Link href="/">
                        <Typography
                            variant="h6"
                            component="a"
                            noWrap
                            href="/"
                            sx={{
                                paddingLeft: "5px",
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Adults Bookmarks
                        </Typography>
                    </Link>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >

                            <MenuItem key="PornSites" onClick={handleCloseNavMenu}>
                                <Link href={"/porn-sites"}>
                                    <Typography textAlign="center">Porn Sites</Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem key="InstagramGirls" onClick={handleCloseNavMenu}>
                                <Link href={"/instagram-girls"}>
                                    <Typography textAlign="center">Instagram Girls</Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem key="TwitterPorn" onClick={handleCloseNavMenu}>
                                <Link href={"/twitter-porn"}>
                                    <Typography textAlign="center">Twitter Porn</Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem key="FreeOnlyFans" onClick={handleCloseNavMenu}>
                                <Link href={"/free-onlyfans"}>
                                    <Typography textAlign="center">Free OnlyFans</Typography>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}>
                        <Image src="images/couple.png" height={32} width={32} alt={"Icon"}/>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Adults Bookmarks
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Link href={"/porn-sites"}>
                            <Button
                                key={"PornSites"}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                Porn sites
                            </Button>
                        </Link>
                        <Link href={"/instagram-girls"}>
                            <Button
                                key={"InstagramGirls"}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                Instagram Girls
                            </Button>
                        </Link>
                        <Link href={"/twitter-porn"}>
                            <Button
                                key={"TwitterPorn"}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                Twitter Porn
                            </Button>
                        </Link>
                        <Link href={"/free-onlyfans"}>
                            <Button
                                key={"FreeOnlyFans"}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                Free OnlyFans
                            </Button>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Link href="https://www.buymeacoffee.com/adultsbookmarks">
                            <a rel="author" target="_blank">
                                <Image src={"/images/bmc-button.png"} height={40} width={142} alt="Buy me a coffee"/>
                            </a>
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
