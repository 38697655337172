import {ThemeProvider} from "@mui/material";
import theme from "../styles/theme";
import "../styles/single.css";
import "../styles/sidebar.css";
import "../styles/singlePost.css";
import "../styles/topbar.css";
import "../styles/globals.css";
import "../styles/websites.css";
import MyAppBar from "../components/MyAppBar";
import {Toaster} from "react-hot-toast";
import Script from "next/script";
import * as React from "react";
import {Instagram} from "@mui/icons-material";
import Link from "next/link";
import TwitterIcon from "@mui/icons-material/Twitter";

function MyApp({Component, pageProps}) {
    return (
        <ThemeProvider theme={theme}>

            <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-1EGZDECMME`}/>

            <Script strategy="lazyOnload" id="my-google-analytics">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-1EGZDECMME', {
                    page_path: window.location.pathname,
                    });
                `}
            </Script>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
            <MyAppBar/>
            <Component {...pageProps} />
            <nav className="social">
                <ul>
                    <li id="twitter">
                        <Link href={"https://twitter.com/AdultsBookmarks"}>
                            <a rel="twitter" target="_blank" className="twitter">
                                <TwitterIcon fontSize="large"/>
                            </a>
                        </Link>
                    </li>
                </ul>
                <ul>
                    <li id="instagram">
                        <Link href={"https://www.instagram.com/adultsbookmarks/"}>
                            <a rel="instagram" target="_blank" className="instagram">
                                <Instagram fontSize="large"/>
                            </a>
                        </Link>
                    </li>
                </ul>
            </nav>
        </ThemeProvider>
    );
}

export default MyApp
