import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
        },
    },
    background: {
        default: "#fff"
    }
});

export default theme;